@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';

:global(#hh) {
    .productInfoAddToCart {
        flex: 1;
    }

    .omnipresentButton {
        flex-shrink: 0;
        font-size: 1rem;
        min-height: 2.875rem;
    }

    .addToCartButton {
        font-size: 1rem;
    }

    .addToCartButtonInactive:not(a):disabled {
        background-color: #272a36;
        color: colors.$color--white;
    }

    .shakeAnimation {
        :global {
            animation: shake-animation 4.72s ease infinite;
        }
    }
}

