@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/variables';

.root {
    margin: auto;
    max-width: variables.$max-screen-width;
    padding: 0 variables.$mobile-page-gutter;
    width: 100%;

    @include vars.mq-medium-up {
        padding: 0 variables.$desktop-page-gutter;
    }
}

.newRoot {
    composes: root;
    padding: 0 0.75rem;

    @include vars.mq-medium-up {
        padding: 0 1rem;
    }
}

.small {
    max-width: 80rem;
}

.xsmall {
    max-width: 52rem;
}

.yotpo {
    padding: 0 0.375rem;

    @include vars.mq-medium-up {
        padding: 0 1.375rem;
    }
}
