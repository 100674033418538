@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.buttonsWrapper {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 0.1rem;
    width: 100%;

    @include responsive.media('>mobile') {
        flex-direction: row;
    }
}

.button {
    @include responsive.media('>mobile') {
        flex: 1;
    }
}

.contentWrapper {
    display: grid;
    gap: 2rem;
}

.maybeFormWrapper {
    > form {
        @include mixins.form-grid();
    }
}
