@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.progressItem {
    + .progressItem {
        margin-top: 1rem;
    }

    @include responsive.media('>tablet') {
        align-items: center;
        display: flex;
        gap: 1rem;
        max-width: 40rem;

        + .progressItem {
            margin-top: 0.5rem;
        }
    }
}

.progressItemWithContent {
    composes: progressItem;

    &:hover {
        color: var(--brand-color--primary, colors.$brand-color--primary);
        cursor: pointer;

        .progressItemBar {
            &::-webkit-progress-value {
                background-color: colors.$progress-bar-background-color--active-hover;
            }

            &::-moz-progress-bar {
                background-color: colors.$progress-bar-background-color--active-hover;
            }
        }
    }
}

.progressItemBar {
    -webkit-appearance: none;
    border-radius: variables.$border-radius;
    flex: 1 1 auto;
    height: 0.375rem;
    overflow: hidden;
    position: relative;
    width: 100%;

    &::-webkit-progress-bar {
        background-color: colors.$progress-bar-background-color;
    }

    &::-moz-progress-bar {
        background-color: colors.$progress-bar-background-color--active;
        border-radius: variables.$border-radius;
        transition: background-color 0.16s ease-in-out;
    }

    &::-webkit-progress-value {
        background-color: colors.$progress-bar-background-color--active;
        border-radius: variables.$border-radius;
        transition: background-color 0.16s ease-in-out;
    }
}

.progressItemTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include responsive.media('>tablet') {
        width: 18rem;
    }
}

.progressItemHeading {
    align-items: center;
    display: flex;
    gap: 0.25rem;
}

.productPerformanceChevron {
    height: 0.5rem;
    width: 0.5rem;
}

// New design
.newProgressItemTitle {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.newProgressItemHeading {
    @include vars.inline-link;
}

.newProgressItem {
    + .progressItem {
        margin-top: 0.5rem;
    }
}

.newProgressItemWithContent {
    composes: newProgressItem;

    &:hover {
        color: var(--color--brand-primary-500);
        cursor: pointer;

        .progressItemBar {
            &::-webkit-progress-value {
                background-color: var(--color--brand-primary-500);
            }

            &::-moz-progress-bar {
                background-color: var(--color--brand-primary-500);
            }
        }
    }
}

.progressItemsList {
    display: grid;
    gap: 1.5rem;

    @include vars.mq-medium-up {
        grid-template-columns: repeat(2, 1fr);
    }
}

.newProgressItemBar {
    composes: progressItemBar;

    &::-webkit-progress-bar {
        background-color: var(--color--gray-200);
    }
}
