@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/fonts';
@use '@/styles/resources/colors';

.root {
    color: colors.$color--white;
    grid-column: var(--mobile-tile-column-position) / span 6;
    grid-row: var(--mobile-tile-row-position);

    @include vars.mq-medium-up {
        grid-column: var(--desktop-tile-column-position) / span 4;
        grid-row: var(--desktop-tile-row-position);
    }

    &.size_of_two_mobile {
        grid-column: var(--mobile-tile-column-position) / span 12;
        @include vars.mq-medium-up {
            grid-column: var(--desktop-tile-column-position) / span 4;
        }
    }

    &.size_of_two_desktop {
        @include vars.mq-medium-up {
            grid-column: var(--desktop-tile-column-position) / span 8;
        }
    }
}

.wrapper {
    position: relative;
}

.contentWrapper {
    display: block;
    padding: calc(100% - 0.75rem) 0.75rem 0;
    width: 100%;
    z-index: 0;

    &.size_of_two_mobile {
        padding: calc(50% - 1.125rem) 0.75rem 0rem;

        @include vars.mq-medium-up {
            padding: calc(100% - 0.75rem) 0.75rem 0;
        }
    }

    &.size_of_two_desktop {
        @include vars.mq-medium-up {
            padding: calc(50% - 1.125rem) 0.75rem 0rem;
        }

        @include vars.mq-large-up {
            padding: calc(50% - 1.25rem) 0.75rem 0rem;
        }
    }
}

.content {
    align-items: center;
    display: flex;
    flex-flow: column;
    height: 100%;
    justify-content: center;
    left: 0;

    padding-inline: 0.5rem;
    position: absolute;
    top: 0;
    width: 100%;
    @include vars.mq-medium-up {
        padding-inline: 2rem;
    }
}

.backgroundImage {
    display: block;
    padding: calc(100% - 0.75rem) 0.75rem 0;
    position: absolute;
    width: 100%;
    z-index: 0;

    &.size_of_two_mobile {
        padding: calc(50% - 1.125rem) 0.75rem 0rem;

        @include vars.mq-medium-up {
            padding: calc(100% - 0.75rem) 0.75rem 0;
        }
    }

    &.size_of_two_desktop {
        @include vars.mq-medium-up {
            padding: calc(50% - 1.125rem) 0.75rem 0rem;
        }

        @include vars.mq-large-up {
            padding: calc(50% - 1.25rem) 0.75rem 0rem;
        }
    }

    img {
        height: 100%;
        left: 0;
        object-fit: cover;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.subtitle {
    font-size: vars.$font-size--sm;
    font-weight: fonts.$font-weight--bold;
    line-height: 1.09375rem;
    text-align: center;
    text-transform: uppercase;
}

.title {
    font-size: vars.$font-size--xl;
    font-weight: fonts.$font-weight--bold;
    line-height: 1.5625rem;
    margin-top: 0.5rem;
    text-align: center;

    @include vars.mq-large-up {
        font-size: vars.$font-size--4xl;
        line-height: 2.8125rem;
    }
}

.subcopy {
    display: none;
    font-size: vars.$font-size--sm;
    font-weight: fonts.$font-weight--medium;
    line-height: 1.09375rem;
    margin-top: 0.5rem;
    text-align: center;

    &.size_of_two_mobile {
        display: inline;
    }

    @include vars.mq-large-up {
        display: inline;
        font-size: vars.$font-size--base;
        line-height: 1.25rem;
        margin-top: 2rem;
    }
}

.buttonsContainer {
    align-items: center;
    display: grid;
    gap: 1rem;
    grid-template-columns: max-content;
    justify-content: center;
    margin-top: 1rem;

    @include vars.mq-large-up {
        gap: 0.5rem;
        grid-auto-columns: minmax(auto, max-content);
        grid-template-columns: repeat(2, 1fr);
        margin-top: 2rem;
    }

    &.withOneButton {
        grid-template-columns: max-content;
    }

    .link:nth-of-type(2) {
        display: none;

        @include vars.mq-large-up {
            display: flex;
        }
    }

    &.size_of_two_mobile {
        grid-auto-columns: minmax(auto, max-content);
        grid-template-columns: repeat(2, 1fr);

        &.withOneButton {
            grid-template-columns: max-content;
        }

        .link:nth-of-type(2) {
            display: flex;
        }
    }
}

.link {
    align-items: center;
    align-self: stretch;
    color: var(--color--gray-800);
    display: flex;
    font-size: vars.$font-size--sm;
    font-weight: fonts.$font-weight--bold;
    justify-content: center;
    line-height: 1.09375rem;
    min-height: 2.5rem;
    padding: 0.6875rem 2rem;

    @include vars.mq-large-up {
        min-height: 3.25rem;
        padding: 0.6875rem 2.96875rem;
    }

    &.withOneButton {
        @include vars.mq-large-up {
            padding: 1.0625rem 6.53125rem;
        }
    }
}
