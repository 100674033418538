@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/variables';
@use '@/styles/resources/responsive';
@use '@/styles/resources/z-index' as zIndex;

.checkoutContent {
    display: grid;

    @include responsive.media('>tablet') {
        grid-template-columns: 47.5% 5% 47.5%;
        margin-top: 2rem;
    }

    @include responsive.media('>desktop') {
        grid-template-columns: 45% 10% 45%;
        margin: 4rem 4rem 0;
    }

    @include responsive.media('>desktop-xl') {
        grid-template-columns: 50% 15% 35%;
        margin: 4rem 4rem 0;
    }

    @include responsive.media('>desktop-xxl') {
        grid-template-columns: 45% 20% 35%;
    }
}

.checkoutContentReviewStep {
    grid-template-columns: 1fr;
    margin-block: 2rem;
    margin-inline: auto;
    max-width: 45rem;
}

.stepsWrapper {
    display: grid;
    gap: 2rem;
    height: fit-content;

    &Review {
        display: grid;
        gap: 2rem;
    }
}

.checkoutPanel {
    margin-bottom: 2rem;
    padding-top: 1rem;
    position: relative;

    @include responsive.media('>tablet') {
        display: none;
    }
}

.checkoutPanelLine {
    align-items: center;
    box-shadow: variables.$header--dropdown-box-shadow;
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    z-index: zIndex.$index--checkout-panel;
}

.checkoutPanelLabel,
.checkoutPanelLink,
.checkoutPanelLink svg {
    font-weight: fonts.$font-weight--bold;
    text-transform: capitalize;
}

.checkoutPanelLink {
    align-items: center;
    display: inline-flex;
    gap: 0.3rem;
}

.checkoutPanelLinkIcon {
    height: 0.7rem;
    width: 0.7rem;
}

.checkoutContentHidden {
    composes: checkoutContent;
    display: none;
}

.headingContainer {
    display: grid;
    row-gap: 1rem;
}

.heading {
    line-height: 1.25em;
}

.stepperHeading {
    @include mixins.checkoutStepTitle();
    border-top: 2px dashed;
    opacity: 0.2;
    padding-top: 1rem;
}

.shippingMethodHeading,
.paymentInformationHeading {
    composes: stepperHeading;
}

.backgroundImageWrapperFullHeight {
    align-items: center;
    display: block;
    height: 100vh;
    margin-bottom: -2rem;
    overflow: hidden;
    position: relative;

    @include responsive.media('>tablet') {
        display: flex;
    }
}

.backgroundImage {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: zIndex.$index--background-image;
}

.checkoutTitle {
    @include mixins.punchTextShadow();
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    gap: 1rem;
    left: 50%;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    @include responsive.media('>phone') {
        font-size: 2.5rem;
    }

    @include responsive.media('>tablet') {
        text-align: left;
    }

    @include responsive.media('>desktop') {
        font-size: 3rem;
    }

    @include responsive.media('>desktop-xl') {
        margin-left: 4rem;
    }
}

.checkoutTitleWithoutImage {
    border-bottom: 1px solid colors.$border-color--dark;
    font-size: 2rem;
    font-weight: fonts.$font-weight--bold;
    line-height: 1.2;
    padding: 1rem 0 0.5rem;
    text-transform: uppercase;

    @include responsive.media('>tablet') {
        border-bottom: none;
        padding-top: 2rem;
    }
    @include responsive.media('>desktop') {
        font-size: 2.5rem;
        padding: 4rem 0 0 4rem;
    }
}

.checkoutTitleOrderReview {
    composes: checkoutTitleWithoutImage;

    @include responsive.media('>tablet') {
        margin-block: 2rem;
        margin-inline: auto;
        max-width: 45rem;
        padding: 0;
    }
}

.checkoutPanelMobileInfo {
    display: grid;

    @include responsive.media('>tablet') {
        display: none;
    }
}

.checkoutSubtitle {
    font-size: 1.2rem;
}

.signInButton {
    min-height: auto;
    min-width: auto;
    padding: 0.25rem 2rem;
}

.emptyCartContainer {
    margin-bottom: 1.5rem;
}

.emptyCheckoutActionButton {
    margin-top: 2rem;
}

.formErrors {
    border-color: colors.$message-text--error;
    border-style: solid;
    border-width: 0 0 0 5px;
    display: grid;
    padding: 1rem 0 1rem 1rem;
}

.reviewOrderButton {
    font-size: 1rem;
    margin: auto;
}

.placeOrderButtonWrapper {
    align-items: center;
    background-color: colors.$color--white;
    bottom: 0;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 1rem 0;
    position: sticky;
}

.recaptchaPolicyText {
    color: colors.$text-color--gray;
}

.recaptchaPolicyLink {
    @include mixins.link-underline(
        $baseColor: colors.$text-color--gray,
        $hoverColor: colors.$text-color--gray,
        $hasUnderline: true
    );
}

.grecaptchaBadge {
    visibility: hidden;
}

.loadingIndicator {
    text-align: center;
}

.placeOrderButton {
    flex: 1 1 auto;
    font-size: 1rem;

    button {
        width: 100%;
    }
}

.signInElement {
    @include responsive.media('<=tablet') {
        display: none;
    }
}

@include responsive.media('<tablet') {
    .paymentInformationHeading {
        /* On mobile, order summary has a top border, so avoid doubling up. */
        border-bottom-width: 0;
    }
}
