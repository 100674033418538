@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

div.root {
    align-items: center;
    gap: 0.5rem;
    grid-template-columns: 50px 1fr;
    margin: 0.75rem 0 0;

    @include responsive.media('>tablet') {
        margin: 1rem 0 0;
    }
}
