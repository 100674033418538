@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.productDetails {
    display: grid;
    grid-template-rows: auto;

    @include vars.mq-medium-up {
        gap: 2.5rem;
        grid-area: description;
    }
}

@include vars.mq-small {
    .productUseForSection {
        order: -1;
    }

    .productDescriptionSection {
        order: -2;
    }
}

// New design
.mainSection {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 3rem;
    padding-top: 1.5rem;

    @include vars.mq-medium-up {
        gap: 2.5rem;
        padding-top: 2.5rem;
    }
}

.newProductDetails {
    gap: 0;
    padding-top: 1rem;

    @include vars.mq-large-up {
        padding-left: 11.25rem;
        padding-right: 6.625rem;
        padding-top: 0;
    }
}
