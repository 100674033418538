@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/mixins';
@use '@/styles/resources/colors';

.root {
    font-size: 0.875rem;

    a {
        @include mixins.link-underline(
            $baseColor: colors.$text-color--primary,
            $hoverColor: colors.$text-color--primary,
            $hasUnderline: true
        );
    }
}

@include vars.mq-small {
    .mobileInlineWrapper {
        display: flex;
        gap: 0.25rem;
    }
}
