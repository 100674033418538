@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

:global(#hh) {
    .masonry {
        display: grid;
        gap: 1.5rem;

        @include responsive.media('>desktop') {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .addAddressIcon {
        stroke: colors.$text-color--primary;
    }

    .addAddressAction {
        background-color: colors.$color--wild-sand;
        border: 2px dashed colors.$border-color--black;
        border-radius: variables.$border-radius;
        color: colors.$text-color--primary;
        font-size: 0.875rem;
        height: 5.75rem;
        padding: 2rem;

        &:hover {
            background-color: colors.$color--white;
            border-color: colors.$border-color--black;
        }
    }
}
