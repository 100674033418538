@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/breakpoints';
@use '@/styles/resources/colors';

.root {
  margin: 2rem auto;
  max-width: breakpoints.$screen--s;
  text-align: center;
}

.heading {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.successIcon {
  color: colors.$notification-icon-color--success;
  height: auto;
  margin: 1rem auto;
  width: 5rem;
}
