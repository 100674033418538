@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';

.root {
    @include mixins.card();
}

.cardHeader {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr;
    place-content: center;
}

.selectedInformationTitle {
    @include mixins.checkoutStepSubtitle();
    font-size: 1rem;
}

.editButton {
    align-items: center;
    display: flex;
    font-size: 0.85rem;
    gap: 0.5rem;

    &Text {
        @include responsive.media('<=tablet') {
            @include mixins.a11y-visually-hidden();
        }
    }
}

.telephone {
    align-items: center;
    display: flex;
}

.telephoneIcon {
    margin-right: 0.5rem;
}

.titleContainer {
    color: colors.$text-color--primary;
    font-weight: fonts.$font-weight--bold;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
}

.cardContentContainer {
    align-content: baseline;
    display: grid;
    font-size: 0.95em;
    hyphens: auto;
    row-gap: 0.1rem;
    word-break: break-word;

    > :first-of-type {
        font-size: 1.1em;
        font-weight: fonts.$font-weight--bold;
    }
}

.successIcon {
    @include mixins.success-icon();
}
