@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';

.root {
    align-items: center;
    background-color: colors.$color--alizarin-crimson;
    border-radius: 50%;
    color: colors.$text-color--white;
    display: flex;
    height: 1.25rem;
    justify-content: center;
    position: absolute;
    right: -0.4rem;
    top: -0.4rem;
    width: 1.25rem;
}

.smallBadge {
    height: 0.8rem;
    width: 0.8rem;
}
