@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.link {
    text-decoration: underline;
}

@include vars.mq-small {
    .productDeclarationItem {
        order: -2;
    }
}
