@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.tileList {
    display: grid;
    gap: 0.25rem;
    grid-template-columns: repeat(auto-fit, minmax(min(100% / 6, max(5.125rem, 100% / 6)), 1fr));

    .tile:only-child {
        max-width: 8.5rem;
    }
}

