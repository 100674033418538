@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/variables';
@use '@/styles/resources/_z-index.scss' as zIndex;


:global(#hh) {
    .root {
        display: none;
    }

    .rootVisible {
        bottom: 1.5rem;
        display: flex;
        justify-content: flex-end;
        position: sticky;
        z-index: zIndex.$index--overlay;
    }

    .backToTopButton {
        border: none;
        border-radius: variables.$border-radius;
        box-shadow: variables.$toast--box-shadow;
        height: 2.875rem;
        padding: 0;
        right: 1.5rem;
        transition: transform 0.5s ease-in-out;
        width: 2.875rem;
    }

    .backToTopIcon {
        color: colors.$color--gray-dark;
        height: 2rem;
        transform: rotate(-90deg);
        width: 1.5rem;
    }
}
