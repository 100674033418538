@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.root {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
}

.title {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
}

.categories {
    margin-top: 2rem;
}

.list {
    margin-top: 1rem;

    &Item {
        text-align: center;
        text-decoration: underline;

        &:not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }
}

.imageContainer {
    margin-bottom: 2rem;
}

.image {
    height: 12.5rem;
    max-width: 12.5rem;
    width: 12.5rem;
}
