@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/responsive';
@use '@/styles/resources/mixins';


.root {
    @include vars.mq-medium-up {
        padding-top: 1rem;
    }
}

.productsGrid {
    color: colors.$text-color--primary;
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
    list-style-type: none;

    @include responsive.media('>mobile') {
        gap: 1rem;
    }

    @include responsive.media('>=tablet') {
        grid-template-columns: repeat(3, 1fr);
    }

    @include responsive.media('>tablet') {
        gap: 1.6rem;
    }

    @include responsive.media('>desktop-xl') {
        grid-template-columns: repeat(4, 1fr);
    }
}

.loaderWrapper {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin: 0 auto;
    min-height: 4.5rem;
    padding: 2rem 0;
    place-items: center;
    text-align: center;
}

.pagination {
    @include mixins.a11y-visually-hidden();
}

// Skeleton Styles
.skeletonProductsGrid {
    composes: productsGrid;
    margin-top: 1rem;

    @include vars.mq-medium-up {
        gap: 0.75rem;
        grid-template-columns: repeat(3, 1fr);
    }

    @include vars.mq-large-up {
        gap: 1rem;
    }
}

.skeletonCardContainer {
    padding-bottom: 0.5rem;
}

.skeletonLink {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.skeletonImageContainer {
    position: relative;
    width: 100%;

    &::before {
        content: '';
        display: block;
        padding-top: 95%;
    }
}

.skeletonImage {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.skeletonCardBody {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    margin-bottom: 0;
}

.skeletonSwatchesContainer {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    padding-bottom: 0.35rem;
}


.skeletonSwatches {
    display: flex;
    gap: 0.5rem;
    list-style: none;
}

.skeletonSwatchesItem {
    height: 0.9rem;
    position: relative;
    width: 0.9rem;
}
