@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
.root {
    display: grid;
    gap: 0.25rem;
    grid-auto-columns: minmax(2rem, auto);
    grid-auto-flow: column;
    justify-content: center;
    padding: 1rem 0;
    text-align: center;
}

.tile {
    align-items: center;
    border: 1px none currentColor;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    min-height: 2rem;
    outline: none;
    position: relative;

    &Active {
        border-style: solid;
    }
}
