@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/variables';
@use '@/styles/resources/mixins';

$galleryMarginTop: 2rem;

.wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
}

.containerFlex {
    display: flex;
    position: relative;

    > div {
        flex: 1 1 auto;
    }

    @include vars.mq-small {
        display: block;
    }
}

.categoryContainer {
    composes: containerFlex;
    margin-top: 2rem;
}

.categoryHeader {
    padding-bottom: 1.5rem;

    @include vars.mq-small {
        padding-bottom: 1rem;
    }

    [class*='richContent'] {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}

.categoryHeaderContainer {
    margin-bottom: 1rem !important;
}

.categoryImage {
    > img {
        width: 100%;
    }
}

.categoryDescription {
    @include vars.body-2;

    > [class*='row_contained'] {
        margin: 0 auto;
        max-width: 100%;

        > div {
            h2,
            h3 {
                @include vars.heading-4;
                margin-bottom: 1rem;
            }
        }

        > div > [class*='text_root'],
        > div > [class*='html_root'] {
            margin: 0 auto !important;
            max-width: variables.$max-screen-width !important;
            padding: 0 variables.$mobile-page-gutter !important;
            width: 100% !important;

            @include vars.mq-medium-up {
                padding: 0 variables.$desktop-page-gutter !important;
            }
        }
    }

    & > * {
        padding: 0 !important;

        &[class*='row_contained'] {
            > div {
                margin: 0 !important;
                padding: 0 !important;

                > h2 {
                    @include vars.heading-4;
                    margin-bottom: 1rem;
                }
            }

            [class*='products-root'] {
                margin-bottom: 1rem !important;
            }
        }
    }

    > [class*='html_root'] {
        margin: 0 auto !important;
        max-width: variables.$max-screen-width !important;
        padding: 0 variables.$mobile-page-gutter !important;
        width: 100% !important;

        @include vars.mq-medium-up {
            padding: 0 variables.$desktop-page-gutter !important;
        }
    }
}

.containerWithBottomDescription {
    border-bottom: 1px solid var(--color--gray-400);
    padding-bottom: 3.5rem;
}

.bottomDescription {
    h2 {
        @include vars.heading-5;
        margin: 0;
        padding: 0 0 0.5rem;
    }

    p {
        margin: 0;
        padding: 0 0 1.5rem;
    }
}

.descriptionAccordion {
    h2 {
        font-size: 1.25rem !important;
        font-weight: fonts.$font-weight--medium !important;
        line-height: 120% !important;
        margin-top: 0 !important;
        text-transform: none !important;
    }

    p {
        margin: 0 !important;
        padding: 0 0 1.5rem !important;
    }

    :global {
        .collapse-container {
            border-bottom: 1px solid var(--color--gray-400);
            cursor: pointer;
            user-select: none;
            width: 100%;
        }

        h2.collapse-container__heading {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 0.875rem !important;
            font-weight: fonts.$font-weight--bold !important;
            gap: 1rem;
            justify-content: space-between;
            line-height: 125% !important;
            margin: 0 !important;
            padding: 0.75rem;
            position: relative;

            &:after {
                content: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22none%22%20stroke-width%3D%222%22%20viewBox%3D%220%200%2024%2024%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolyline%20points%3D%226%209%2012%2015%2018%209%22%3E%3C%2Fpolyline%3E%3C%2Fsvg%3E);
                transition: transform 0.2s;
            }
        }

        .active-collapse {
            &:after {
                transform: rotate(-180deg);
            }
        }

        .collapse-container__body {
            p {
                @include vars.body-2;

                margin-bottom: 0 !important;
                padding: 0.75rem !important;
            }
        }
    }
}

:global(#hh) {
    .categoryFooter {
        @include vars.body-2;
        padding-top: 3.5rem;
    }

    .subCategoriesContainer {
        margin-bottom: 0.5rem;
        overflow: hidden;
        position: relative;
    }
}

.subCategoriesList {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5rem;
    overflow: auto;
    padding-bottom: 1.5rem;

    > * {
        white-space: nowrap;
    }

    @include vars.mq-small {
        margin-bottom: 0.5rem;
        padding-bottom: 1rem;
    }
}

.scrollDownCallToAction {
    align-items: center;
    display: flex;
    gap: 1rem;
}

.categoryHeaderSkeleton {
    display: grid;
    gap: 1rem;

    [class*='richContent'] {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        & > * {
            grid-column: 1 / -1;
            max-width: variables.$max-screen-width;
            padding: 0 variables.$mobile-page-gutter !important;
            width: 100%;

            @include vars.mq-medium-up {
                padding: 0 variables.$desktop-page-gutter !important;
            }

            &[class*='fullBleed'] {
                margin: 0 !important;
                max-width: 100%;
                padding: 0 !important;
                transform: none !important;
            }
        }
    }

    h1 {
        [class*='richContent'] {
            display: block;
        }
    }
}

.categoryTitle {
    @include vars.heading-4;

    @include vars.mq-medium-up {
        @include vars.heading-3;
    }

    [class*='richContent'] {
        display: block;
    }
}

// Used for smooth scrolling in useFilterBar
.smoothContainerScroll {
    scroll-margin-top: calc(var(--filter-bar-height, 0px) + var(--header-general-height, 0px) + $galleryMarginTop);
}
