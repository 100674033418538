@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.productDescription {
    font-size: 0.875rem;

    @include vars.mq-small {
        margin-bottom: 2rem;
    }
}

.productDescriptionTitle {
    text-transform: none;
}

.productDescriptionContent {
    margin-top: 0.8rem;
    padding: 0;

    a {
        @include vars.inline-link;
    }
}

// New design
.newProductDescription {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include vars.mq-medium-up {
        gap: 1.5rem;
    }
}

.newProductDescriptionTitle {
    @include vars.heading-5;
    color: var(--color--black);
}

.newProductDescriptionContent {
    @include vars.body-2;
    color: var(--color--gray-800);

}
