@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';

.productDetails {
    display: grid;
    gap: 0.5rem;

    @include responsive.media('>=desktop') {
        grid-template-columns: auto 1fr;
    }

    & span {
        font-weight: fonts.$font-weight--medium;
    }
}

.productDetailsCols {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);

    @include responsive.media('>=desktop') {
        grid-column: 1 / span 2;
    }
}

.productDetailsRow {
    display: flex;
    gap: 0.3rem;

    span {
        font-weight: fonts.$font-weight--medium;
    }
}
