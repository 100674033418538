@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';

.root {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 2rem 1rem 0;

    > div {
        height: 100%;

        @include responsive.media('<phone-xl') {
            width: 100%;
        }

        > div {
            height: 100%;

            @include responsive.media('<phone-xl') {
                width: 100%;
            }
        }
    }

    iframe {
        height: 100% !important;
        width: 100% !important;
    }
}
