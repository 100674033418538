@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';

.list {
    padding: 0.75rem 0;
}

.filterWrapper {
    @include vars.mq-small {
        border-top: 1px solid var(--color--gray-400);
    }
}
