@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'sass:color';
@use '@/styles/resources/colors';

.swatchWrapper {
    display: flex;
    gap: 0.75rem;
    line-height: 1;
}

.swatch {
    color: colors.$text-color--secondary;
    position: relative;

    &:focus,
    &:focus-visible,
    &:active,
    &:hover {
        outline: none;

        &::before {
            background: color.adjust(colors.$text-color--primary, $lightness: 55%);
        }
    }

    &::before {
        background: 0 0;
        bottom: -0.5rem;
        content: '';
        height: 2px; // avoid height squishing by explicitly setting this value in px
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        transition: 0.3s;
        width: 1rem;
    }
}

.swatchSelected {
    &:focus,
    &:focus-visible,
    &:active,
    &:hover {
        outline: none;

        &::before {
            background: colors.$text-color--primary;
        }
    }

    &::before {
        background: #1c1c1c;
    }
}

.swatchDisabled {
    border: 0;
    opacity: 0.2;
}
