@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.root {
    padding: 2rem 0;
}

.confirmationSection {
    display: grid;
    gap: 1.15rem;
    margin: 0 auto 2rem;
    max-width: 50rem;
    position: relative;
    text-align: center;

    @include mixins.no-print();
}

.confirmationSectionTitle {
    font-size: 1.5rem;
    font-weight: fonts.$font-weight--bold;
    text-transform: uppercase;
}

.emailField {
    text-decoration: underline;
}

.actionSection {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.divider {
    border-top: 1px solid colors.$border-color--tertiary;
    margin: 2rem 0;
}

.actionButton {
    @include responsive.media('<tablet') {
        width: 100%;
    }
}

.cancelOrderButton {
    @include mixins.link-underline();
    text-decoration: underline;
}

.printButtonSection {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    text-align: right;

    @include mixins.no-print();
}

.printButton {
    align-items: center;
    display: flex;
    font-size: 0.8rem;
    gap: 0.3rem;
    @include mixins.link-underline(colors.$text-color--gray, colors.$text-color--gray, $hasUnderline: true);
}

.printButtonIcon {
    height: 1.2rem;
    margin-right: 0.2rem;
    stroke: currentColor;
    width: 1.2rem;
}

.orderSection {
    display: grid;
    font-size: 0.857rem;
    gap: 1rem;

    @include responsive.media('>=tablet') {
        grid-template-columns: repeat(3, 1fr);
    }
}

.notificationContent {
    > div {
        display: unset;
    }
}

.cancelRequestSuccessMessageWrapper {
    gap: 1rem;
    margin-bottom: 1rem;
}

.orderDetails {
    align-content: flex-start;
    background-color: colors.$color--wild-sand;
    border: 1px solid colors.$border-color--tertiary;
    border-radius: variables.$border-radius;
    display: grid;
    font-size: 0.875rem;
    gap: 0.5rem;
    padding: 1rem;
}

.orderDetailsTitle {
    border-bottom: 1px solid colors.$border-color--tertiary;
    font-size: 1.1rem;
    font-weight: fonts.$font-weight--medium;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
}

.orderDetailsLabel {
    font-weight: fonts.$font-weight--medium;
    margin-right: 0.3rem;
}

.orderDetailsMarkText {
    font-weight: fonts.$font-weight--medium;
}

.orderShippingInfo {
    display: grid;
    gap: 0.2rem;
}

.orderReview {
    align-items: flex-start;

    @include responsive.media('>=tablet') {
        composes: orderSection;
        grid-column: 1/4;
    }
}

.orderItems {
    @include responsive.media('>=tablet') {
        grid-column: 1/3;
    }
}

.orderSummary {
    align-content: flex-start;
    background-color: colors.$color--wild-sand;
    border: 1px solid colors.$border-color--tertiary;
    border-radius: variables.$border-radius;
    display: grid;
    gap: 0.5rem;
    padding: 1rem;

    @include responsive.media('>=tablet') {
        grid-column: 3;
    }
}

