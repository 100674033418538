@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/fonts';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

.root {
    align-items: center;
    -webkit-appearance: none;
    border: 2px solid var(--brand-color--primary, colors.$brand-color--primary);
    border-radius: variables.$border-radius;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    font-weight: fonts.$font-weight--bold;
    gap: 0.5rem;
    justify-content: center;
    outline: none;
    overflow: hidden;
    padding: 0.625rem 1.5rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transition: background-color, 0.16s ease-in-out;
    user-select: none;

    &:focus {
        outline: none;
    }
}

.root.buttonLinkRoot {
    display: inline-flex;
}

.primary {
    composes: root;
    background-color: colors.$button-background-color--primary;
    color: colors.$text-color--secondary;

    &:hover {
        filter: brightness(0.95);
    }

    &:active,
    &:focus-visible {
        filter: brightness(0.9);
    }
}

.secondary {
    composes: root;
    background-color: colors.$button-background-color--secondary;
    border-color: colors.$text-color--gray;

    &:hover {
        filter: brightness(0.95);
    }

    &:active,
    &:focus-visible {
        filter: brightness(0.9);
    }
}

.tertiary {
    composes: root;
    background-color: colors.$button-background-color--tertiary;
    border-color: colors.$button-background-color--tertiary;
    color: colors.$text-color--secondary;

    &:hover {
        filter: brightness(0.95);
    }

    &:active,
    &:focus-visible {
        filter: brightness(0.9);
    }
}

.quaternary {
    composes: root;
    background-color: colors.$button-background-color--quaternary;
    border-color: colors.$button-background-color--quaternary;
    color: colors.$text-color--primary;

    &:hover {
        filter: brightness(0.95);
    }

    &:active,
    &:focus-visible {
        filter: brightness(0.9);
    }
}

.disabled {
    composes: root;
    background-color: colors.$button-background-color--disabled;
    border-color: colors.$button-background-color--disabled;
    box-shadow: none;
    color: colors.$text-color--gray;
    cursor: default;
    pointer-events: none;
    touch-action: none;

    &:hover,
    &:active {
        background-color: colors.$button-background-color--disabled;
        border-color: colors.$button-background-color--disabled;
        box-shadow: none;
        left: 0;
        top: 0;
    }
}

.isSmall {
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    width: auto;
}

.isFullWidth {
    width: 100%;
}

.link,
.trigger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;

    &:focus {
        outline: none;
    }
}

@include responsive.media('>phone', 'pointer-fine') {
    .root {
        width: auto;
    }

    .isFullWidth {
        width: 100%;
    }
}
