@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/colors';
@use '@/styles/resources/mixins';
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

:global(#hh) {
    .root {
        padding-top: 2.5rem;
    }
}

.pageBody {
    display: grid;
    gap: 2rem;
    grid-template-areas:
        'items'
        'adjustments'
        'summary';
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
}

.pageBodyEmptyCart {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 5rem 0;
}

.headingContainer {
    display: grid;
    margin-bottom: 1rem;
    row-gap: 1rem;
}

.heading {
    @include mixins.heading-1();
    font-size: 2.25rem;
    text-transform: none;
}

.headingEmptyCart {
    @include mixins.heading-3();
}

.stockStatusMessageContainer {
    padding-bottom: 1rem;

    &:empty {
        display: none;
    }
}

.itemsContainer {
    grid-area: items;
}

.priceAdjustmentsContainer {
    grid-area: adjustments;
}

.summaryContainer {
    grid-area: summary;
    height: 100%;
}

.summaryBackground {
    border: 1px solid colors.$border-color--tertiary;
    border-radius: variables.$border-radius;
    padding: 1rem;
}

.summaryTitle {
    @include mixins.heading-2();
    color: var(--color--gray-800);
    text-transform: none;

    &Wrapper {
        align-items: center;
        border-bottom: 1px solid colors.$border-color--tertiary;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5rem;
    }
}

.summaryTitleWrapper + .summaryBody {
    border-top: 0;
}

.summaryBody {
    border-top: 1px solid colors.$border-color--dark;
    margin-top: 0.5rem;
    padding-top: 1rem;
}

@include responsive.media('>desktop') {
    .pageBody {
        grid-template-areas:
            'items summary'
            'adjustments summary';
        grid-template-columns: 2fr 1fr;
    }
}
