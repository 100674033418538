@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use '@/styles/resources/responsive';
@use '@/styles/resources/variables';

@import 'dialog.module';

.confirmationDialog {
    @include responsive.media('<=mobile') {
        border-radius: variables.$border-radius;
        width: 80%;
    }
}
